import firebase from "firebase";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDo-ArFmTw6u1-SgdhgXvasd2IoCS6uQnA",
  authDomain: "firstclass-a7007.firebaseapp.com",
  projectId: "firstclass-a7007",
  storageBucket: "firstclass-a7007.appspot.com",
  messagingSenderId: "928273710060",
  appId: "1:928273710060:web:424e255690949914821ec9",
  measurementId: "G-PJDL71Z4VQ"
};


const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider };
export default db;
